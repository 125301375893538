.case-studies-hero {
  height: 25rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../asset/phone-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.case-studies-hero > div {
  padding-top: clamp(8rem, 5vw, 5rem);
}
.case-studies-hero h1 {
  font-size: clamp(2.2rem, 5vw, 3rem);
  margin-bottom: 1rem;
  color: var(--text-white);
}
.case-studies-hero p {
  max-width: 40ch;
  color: var(--text-white);
  line-height: var(--lh);
}
/* case studies cards */
.cs-cards-section {
  width: 92%;
  margin-right: auto;
  margin-left: auto;
  margin-top: -6rem;
  margin-bottom: 9rem;
}
.clients.case-studies-card-wrapper {
  margin-bottom: 4rem;
}
.case-studies-card-wrapper::before {
  display: none;
}

.cs-cw2 {
  background: url("../asset/photobg.png");
  background-size: cover;
  background-position: center;
}

.cs-cw3 {
  background: url("../asset/view.png");
  background-size: cover;
  background-position: center;
}
.cs-cw4 {
  background: url("../asset/icreate-bg.webp");
  background-size: cover;
  background-position: center;
}
.cs-cw5 {
  background: url("../asset/clout-bg2.png");
  background-size: cover;
  background-position: center;
}
.cs-cw2 .cc-cta-btn,
.cs-cw4 .cc-cta-btn,
.cs-cw6 .cc-cta-btn {
  color: var(--text-black2);
  background: none;
  border: 1.5px solid var(--text-black2);
}
@media screen and (min-width: 1000px) {
  .case-studies-hero {
    height: 32rem;
  }
  .cs-cards-section {
    width: initial;
    margin-top: -9.4rem;
    margin-right: auto;
    margin-left: auto;
  }
  .cs-cw2 .client-cards,
  .cs-cw4 .client-cards,
  .cs-cw6 .client-cards {
    justify-content: flex-end;
  }
}
