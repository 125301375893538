.blog-section {
  height: 100vh;
  display: grid;
  place-items: center;
  text-align: center;
}
.blog-section img {
  width: 300px;
  margin-bottom: 20px;
}
@media screen and (min-width: 1000px) {
  .blog-section img {
    width: 500px;
  }
}
