.case-study-container {
  width: 88%;
  margin: 0 auto;
  max-width: 700px;
}
.case-study-header {
  /* background-color: var(--primary-clr2); */
  background-repeat: no-repeat;
}
.case-study-header-content {
  display: grid;
  place-items: center;
  min-height: 100vh;
  text-align: center;
  padding: 8rem 0;
  color: #fff;
}
.case-study-header-content h1 {
  font-size: clamp(2.4rem, 5vw, 3.5rem);
  line-height: 1.2;
  max-width: 700px;
  margin: 0 auto 2rem;
  text-transform: capitalize;
}
.case-study-header-content div p {
  line-height: var(--lh);
  max-width: 700px;
  margin: 0 auto 2rem;
}
.case-study-cta-btn {
  width: 10rem;
  height: 2.8rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  border-radius: var(--br);
  background: none;
  border: 1px solid #222;
  outline: 0;
}
.case-study-header-content .case-study-cta-btn {
  border: 1px solid #fff;
  color: #fff;
}
.case-study-cta-btn span {
  margin-left: 1rem;
  width: 31px;
  height: 31px;
  display: grid;
  place-items: center;
  background: var(--gradient);
  border-radius: 50%;
}
.case-study-cta-btn svg {
  width: 20px;
  color: #222;
}
.case-study-header-content .case-study-cta-btn svg {
  width: 20px;
  color: #fff;
}
.case-study-body-content {
  min-height: 100vh;
  display: grid;
  place-items: center;
  text-align: center;
  padding: 4rem 0;
}
.case-study-body-content.case-study-body-content-bg {
  background-color: var(--primary-clr2);
  color: #fff;
}
.case-study-body-content.case-study-body-content-bg p,
.case-study-body-content.case-study-body-content-bg ul li {
  color: #fff;
}
.case-study-body-content.case-study-body-content-bg .case-study-cta-btn {
  border: 1px solid #fff;
  color: #fff;
}
.case-study-body-content.case-study-body-content-bg .case-study-cta-btn svg {
  width: 20px;
  color: #fff;
}
.case-study-body-content h2 {
  font-size: clamp(1.8rem, 5vw, 2rem);
  padding-top: 5rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  text-decoration: underline;

  text-decoration-color: var(--primary-clr2);
  text-decoration-thickness: 15%;
  text-underline-offset: 8px;
}
.case-study-body-content-bg h2 {
  text-decoration-color: var(--primary-clr1);
  text-decoration-thickness: 15%;
  text-underline-offset: 8px;
}
.case-study-body-content p {
  color: var(--text-black2);
  margin-bottom: 2rem;
  line-height: var(--lh);
}

.case-study-body-content ul {
  color: var(--text-black2);
  margin-bottom: 2rem;
}
.case-study-body-content ul li {
  font-size: var(--text-size);
  color: var(--text-black2);
  line-height: var(--lh);
}
@media screen and(min-width: 1000px) {
  .case-study-header-content {
    min-height: calc(100vh - 5rem);
  }
}
