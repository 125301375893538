.error-page {
  height: calc(100vh - 5rem);
  display: grid;
  place-items: center;
}
.error-page div {
  text-align: center;
}
.error-page img {
  width: 300px;
  margin-bottom: 20px;
}
@media screen and (min-width: 1000px) {
  .error-page img {
    width: 500px;
  }
}
