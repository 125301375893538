.desktop-menu {
  display: none;
}
@media screen and (min-width: 1000px) {
  .desktop-menu {
    display: block;
    border: 1px solid #eaeaea;
  }
  .desktop-menu-header {
    height: 5rem;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }
  .xl-logo {
    width: 20%;
  }
  .desktop-main-nav {
    width: 55%;
    height: 100%;
    display: grid;
    place-items: center;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
  }
  .xl-navigation-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .desktop-main-nav .xl-navigation-list a {
    font-size: var(--btn-text);
    color: var(--text-black2);
  }
  .xl-navigation-list a.active {
    text-decoration: underline;
  }

  .xl-nav-actions {
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .xl-nav-actions > a,
  .xl-nav-actions .search-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #eaeaea;
  }
  .xl-nav-actions > a {
    width: 22%;
    display: grid;
    place-items: center;
    padding: 0.5rem;
  }
  .xl-nav-actions svg {
    margin: 0 auto;
    width: 25px;
    color: var(--text-black2);
  }
  /* .xl-nav-actions .search-wrapper {
    width: 15%;
  } */
  .xl-btn-wrapper {
    width: 100%;

    display: flex;
    justify-content: flex-end;
  }
  .xl-cta-btn {
    width: 13rem;
    height: 2.8rem;

    /* font-weight: 600; */
    display: grid;
    place-items: center;
    border-radius: var(--br);
    color: var(--text-white);
    background: linear-gradient(84.31deg, #fd4101 9.27%, #ffad01 96.84%);
  }
}
