/* ================
      HERO CONTENT
  ================= */
.hero-content {
  margin: 0 0 6rem;
  padding-top: 8rem;
}
.hero-text-content {
  text-align: center;
  margin-bottom: 3rem;
}
.hero h1 {
  font-size: clamp(2.2rem, 5vw, 3.4rem);
  margin-bottom: 1.8rem;
}
.hero p {
  font-size: clamp(var(--text-size), 5vw, 1.4rem);
  line-height: 1.6;
  margin-bottom: 2.5rem;
}
.hero-cta-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}
.hero-cta-btns span {
  width: 100%;
  height: 3rem;
  font-size: var(--btn-text);
  transition: var(--transition);
}
.hero-cta-btns span:first-child {
  margin-bottom: 1rem;
}
.hero-cta-btns span > .hero-cta {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: var(--br);
}
.hero-cta-btn1 {
  background: var(--gradient);
  color: var(--text-white);
}
.hero-cta-btn2 {
  background: none;
  border: 2px solid var(--text-black);
  color: var(--text-black);
}
.hero-img-content {
  display: flex;
  justify-content: center;
}

/* ================
     CLIENTS
  ================= */
.clients-header {
  margin-bottom: 2.5rem;
  text-align: center;
}
.clients-header h1 {
  font-size: clamp(3rem, 5vw, 4.2rem);
  font-family: Neon;
  margin-bottom: 10px;
}
.clients {
  position: relative;
  padding: 0 1rem;
  height: 33rem;
  background: url("../asset/clientsbg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 6px;
  overflow: hidden;
}
.client-cards {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-card {
  max-width: 400px;
  padding: 3rem;
  text-align: center;
  background: var(--text-white);
  border-radius: 0.3rem;
  box-shadow: var(--bs);
  transition: all 0.5s ease-in-out;
}
.client-card img {
  max-width: 100%;
  width: 11rem;
  margin-bottom: 2rem;
}
.client-card p {
  font-size: var(--text-size);
  color: var(--text-black3);
  margin-bottom: 2rem;
  line-height: 1.7;
}
.cc-cta-btn {
  width: 12rem;
  height: 2.8rem;
  margin: 0 auto;
  display: grid;
  place-items: center;
  font-size: var(--btn-text);
  color: var(--text-white);
  background: var(--primary-clr1);
  text-transform: capitalize;
  font-weight: 400 !important;
  border-radius: var(--br);
}
/* ================
   CLIENTS GALLERY
  ================= */
/* .clients-gallery-section {
  overflow-x: hidden !important;
} */
.clients-galleries {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.5rem;
  overflow: hidden;
}
.client-gallery-wrapper {
  margin-bottom: 4rem;
}
.client-gallery {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: var(--transition);
}
.client-gallery::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.client-gallery:hover img {
  transform: scale(1.1);
  transition: var(--transition);
}
.client-gallery:hover::after {
  background: rgba(0, 0, 0, 0.2);
}
.client-gallery img {
  transition: var(--transition);
}
.cg1 {
  grid-column: 1/7;
  grid-row: 1/7;
  margin-top: -2rem;
}
.cg2 {
  grid-column: 7/13;
  grid-row: 1/7;
}
.cg3 {
  grid-column: 1/7;
  grid-row: 7/13;
}
.cg4 {
  grid-column: 7/13;
  grid-row: 7/13;
  margin-top: 1.5rem;
}

.client-gallery img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.client-gallery-texts {
  width: 88%;
  max-width: 900px;
  margin: 0 auto 4rem;
}
.client-gallery-texts p {
  font-size: var(--text-size);
  color: var(--text-black3);
  line-height: var(--lh);
}
.client-gallery-texts p:nth-child(1) {
  margin-bottom: 1.2rem;
}
.client-gallery-texts p:nth-child(1)::first-letter,
.client-gallery-texts p:nth-child(2)::first-letter {
  font-size: clamp(1.6rem, 5vw, 2rem);
}

.news-galleries {
  display: flex;
  gap: 1rem;
}
/* ================
        SERVICES
  ================= */
.services-content {
  padding: 0.5rem;
  margin-bottom: 8.8rem;
  padding-top: 6rem;
  box-shadow: 0px 70px 80px rgba(0, 0, 0, 0.1);
}
.services-header-content {
  background: linear-gradient(
      180deg,
      rgba(254, 119, 1, 0.8),
      rgba(254, 119, 1, 0.8)
    ),
    url("../asset/servicesbg.png");
  padding: 3rem 0;
  margin-bottom: 3.9rem;
  color: var(--text-white);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 6px;
  overflow: hidden;
}
.services-header-content h2 {
  font-size: 2.2rem;
  font-size: clamp(2.2rem, 5vw, 3rem);
  margin-bottom: 1.4rem;
}
.services-header-content p {
  font-size: var(--text-size);
  max-width: 35ch;
  margin-bottom: 1.6rem;
  line-height: var(--lh);
}
.services-cta-btn {
  display: inline-block;
  width: 11.8rem;
  height: 2.8rem;
  font-size: var(--btn-text);
  display: grid;
  place-items: center;
  color: var(--text-white);
  border: 2px solid var(--text-white);
  border-radius: var(--br);
}
.services-card {
  margin-bottom: 2rem;
}
.services-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.services-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
}
.services-card-header img {
  margin-right: 1rem;
}
.services-card-header h3 {
  font-size: 1.4rem;
  font-weight: 600;
}
.services-list li {
  font-size: var(--text-size);
  color: var(--black2);
  line-height: 2;
  color: #444;
}
/* ================
     BRAND
  ================= */
.works {
  margin-bottom: 8.8rem;
}
.works {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.work-content-header {
  text-align: center;
}
.work-content-header h4 {
  font-size: clamp(2rem, 5vw, 2.5rem);
  margin-bottom: 1.2rem;
}
.work-content-header p {
  font-size: var(--text-size);
  color: var(--black2);
  margin-bottom: 2.4rem;
}
.logo-card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 1rem;
}
.logo-card {
  width: 100%;
  height: 130px;
  display: grid;
  place-items: center;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 4px;
}

.logo-card img {
  max-width: 100%;
  width: 12rem;
}
/* ================
     FOOTER
  ================= */
footer {
  background-color: var(--bg-black2);
}
.footer-text-content-container {
  height: 24rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: var(--text-white);
  border-bottom: 1px solid #333;
}
.footer-text-content {
  max-width: 380px;
  margin: 0 auto;
}
.footer-logo img {
  width: 6rem;
  margin-bottom: 29px;
}
.footer-text-content-container p {
  font-size: var(--text-size);
  margin-bottom: 2.2rem;
  line-height: 30px;
}
.socials a {
  margin: 0 15px;
  transition: all 0.3s ease-in-out;
}
.socials a svg {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.socials a:hover .svg {
  fill: var(--primary-clr1);
}
.footer-attr-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 0;
}
.quick-links-list {
  margin-bottom: 1rem;
}
.quick-links-list li {
  margin-bottom: var(--spacing2);
}
.quick-links-list a {
  font-size: 14px;
  color: #8f8f8f;
  transition: all 0.3s ease-in-out;
}
.quick-links-list a:hover {
  color: var(--primary-clr1);
}
.copyright p {
  font-size: 14px;
  color: #8f8f8f;
}
@media screen and (min-width: 700px) {
  .hero-cta-btns {
    flex-direction: row;
    gap: 1rem;
  }
  .hero-cta-btns span:first-child {
    margin-bottom: 0;
  }
  .services-header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1000px) {
  .mobile-nav {
    display: none;
  }
  .hero-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8rem;
    padding-top: 2rem;
  }
  .hero-text-content {
    margin-bottom: 0;
    text-align: left;
  }
  .hero-text-content {
    width: 60%;
  }
  .hero-text-content p {
    width: 40ch;
  }
  .hero-cta-btns {
    margin: 0;
    justify-content: flex-start;
  }
  .hero-cta-btns {
    width: 75%;
    max-width: initial;
    flex-direction: row;
  }
  .hero-cta-btns a {
    width: 80%;
  }
  .hero-cta-btns a:first-child {
    margin-bottom: 0;
    margin-right: 2rem;
  }
  .hero-img-content {
    width: 40%;
    display: flex;
    justify-content: flex-end;
  }
  .clients,
  .services-content {
    width: 88%;
    margin: 0 auto;
    max-width: 1500px;
  }
  .clients {
    height: 32rem;
    margin-bottom: 9.5rem;
    border-radius: 6px;
  }
  .client-cards {
    padding: 0 3rem;
    justify-content: flex-start;
  }
  .client-card {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .cc {
    padding: 5.3rem 2.6rem;
  }
  .clients-gallery-section {
    margin-bottom: 6rem;
  }
  .clients-galleries {
    height: 750px;
  }
  .news-galleries {
    height: 400px;
  }
  .news-galleries {
    max-width: 1000px;
    margin: 0 auto;
  }
  .news-galleries div img {
    object-fit: contain;
  }
  .cg1 {
    grid-column: 1/5;
    grid-row: 1/13;
    margin-top: 0;
  }
  .cg2 {
    grid-column: 5/9;
    grid-row: 1/7;
  }
  .cg3 {
    grid-column: 9/13;
    grid-row: 1/13;
  }
  .cg4 {
    grid-column: 5/9;
    grid-row: 7/13;
    margin-top: 0;
  }
  .services-content {
    margin-bottom: 8.8rem;
    padding-top: 0;
  }
  .services-header-content {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .services-header-content-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .services-cards {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 4rem;
  }
  .services-card {
    margin-bottom: 0;
  }
  .footer-attr-content {
    flex-direction: row;
    justify-content: space-between;
  }
  .socials a {
    margin: 0 1.6rem;
  }
  .quick-links-list {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
    order: 1;
    margin-bottom: 0;
  }
  .quick-links-list li {
    margin-bottom: 0;
  }
  .copyright {
    display: flex;
    justify-content: flex-start;
    width: 40%;
  }
  /* .testimonials,
  .testimonials-wrapper {
    min-height: 35rem;
  }
  .testimonials-content {
    padding: 3rem;
  }
  .testimonial-slider-btn {
    display: flex;
    justify-content: flex-end;
  } */
}
@media screen and (min-width: 1200px) {
  .hero-text-content {
    width: 55%;
  }
  .hero-img-content {
    width: 45%;
  }
}
@media screen and (min-width: 1400px) {
  .clients,
  .services-content {
    width: 88%;
  }
  .hero p {
    font-size: 1.5rem !important;
  }
}

.privacy-screen {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary-clr2);
  z-index: 100000;
}
