.client_testimonial_header {
  text-align: center;
  margin-bottom: 2rem;
}
.client_testimonial_header h4 {
  font-size: clamp(2rem, 5vw, 2.5rem);
  margin-bottom: 4px;
}
.client_testimonials_section {
  position: relative;
  background-color: #2b292d;
}
.slick-slider {
  min-height: 400px;
  display: flex;
  align-items: center;
}
.testimonial_content {
  min-height: 400px;
  display: flex !important;
  align-items: center !important;
  color: #fff;
  padding: 2rem 1rem 2rem;
}
.testimonial_content p {
  line-height: var(--lh);
  font-size: var(--text-size);
}
.testimonial_content-text {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--text-gray);
  margin-bottom: 1rem;
}
.testimonial_content-author {
  text-transform: uppercase;
}
.testimonial_content-position {
  margin-bottom: 0 !important;
  font-family: "Gilroy-RegularItalic", "Roboto";
}

.slick-dots {
  bottom: 8px !important;
  color: #fff !important;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: white !important;
}
.slick-dots li button:before {
  color: var(--text-gray2) !important;
}
.client_testimonial_arrow button {
  background: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  outline: 0;
}
.client_testimonial_arrow button:nth-child(1) {
  left: 5px;
}
.client_testimonial_arrow button:nth-child(2) {
  right: 5px;
}
.client_testimonial_arrow button svg {
  width: 24px;
  color: var(--text-gray);
}
.slick-prev:before,
.slick-next:before {
  display: none !important;
}
@media screen and (min-width: 1000px) {
  .testimonial_content {
    padding: 2rem 2rem 2rem;
  }
  .client_testimonial_arrow button:nth-child(1) {
    left: 20px;
  }
  .client_testimonial_arrow button:nth-child(2) {
    right: 20px;
  }
}
