.contact-content {
  padding: 7rem 0 5rem;
}
.contact-form {
  margin-bottom: 4rem;
}
.contact-form form {
  width: 100%;
}
.contact-form h1 {
  font-size: clamp(2rem, 5vw, 42px);
  margin-bottom: 1.5rem;
}
.contact-form h1 span {
  font-family: "Gilroy-UltraLightItalic";
  text-decoration: underline;
  text-decoration-color: var(--primary-clr2);
  text-decoration-thickness: 12%;
  text-underline-offset: 5px;
}
.contact-form form input {
  width: 100%;
  display: block;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 1.5rem;
  outline: 0;
  border: 0;
  border-bottom: 2px solid var(--text-gray2);
  transition: var(--transition);
}
.contact-form form input:focus,
.contact-form form input:hover {
  border-bottom: 2px solid var(--text-black);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.contact-form form button {
  font-size: 18px;
  display: block;
  background: none;
  background: var(--text-black3);
  margin-top: 2.5rem;
  padding: 0.8rem 1.2rem;
  color: #fff;
  border-radius: 2rem;
  border: 0;
  outline: 0;
}
.contact-location h1 {
  font-size: clamp(2rem, 5vw, 42px);
  margin-bottom: 1.5rem;
}
.contact-location h1 span {
  font-family: "Gilroy-UltraLightItalic";
  text-decoration: underline;
  text-decoration-color: var(--primary-clr2);
  text-decoration-thickness: 12%;
  text-underline-offset: 5px;
}
.contact-info {
  margin-bottom: 2rem;
}
.contact-info p {
  font-size: 20px;
  color: var(--text-gray);
  margin-bottom: 0.2rem;
  text-transform: uppercase;
}
.contact-info span {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--text-black2);
}
.contact-info div {
  display: flex;
  flex-direction: row;
}
.contact-info div a {
  font-size: 1.5rem;
  color: var(--text-black2);
  margin-right: 2rem;
}
.contact-info div a {
  color: var(--text-black2);
}
.contact-info div a span {
  color: var(--text-black2);
}

.contact-info div a span {
  display: none;
}
@media screen and (min-width: 700px) {
  .contact-info div {
    display: flex;
    flex-direction: column;
  }
  .contact-info div a span {
    display: inline-block;
    margin-bottom: 1rem;
  }
}
@media screen and (min-width: 1000px) {
  .contact-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3rem;
  }
  .contact-form {
    width: 50%;
    margin-bottom: 0;
  }
  .contact-form form input {
    font-size: 18px;
    padding: 10px;
    margin-bottom: 1.5rem;
  }
  .contact-info {
    margin-bottom: 3rem;
  }
  .contact-location {
    width: 40%;
    padding-top: 0.6rem;
  }
  .contact-location h1 {
    margin-bottom: 2.2rem;
  }
}
