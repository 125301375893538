/* ==============
    Mobile Nav
  =============== */
.mobile-menu-header {
  display: flex;
  align-items: center;
  height: 5rem;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  padding: 0 0.6rem;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  z-index: 10000;
}
.logo img {
  width: 6rem;
}
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--bg-white);
  opacity: 0;
  visibility: hidden;
  transition: all 0.375s;
  padding-top: 5.5rem;
  z-index: -1;
  overflow: hidden;
}
.navigation.is-open {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}
.navigation::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -15px;
  background: var(--primary-clr2);
  transform-origin: 0 0;
  transform: skew(-14deg) translateX(-120%);
  transition: all 0.275s 0.1s;
  z-index: -1;
}
.navigation.is-open::before {
  transform: skew(-16deg) translateX(0);
}
.navigation-list {
  height: 60vh;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.navigation-list li {
  margin: 0.8rem 0;
}
.navigation-list a {
  font-size: 1.4rem;
  color: var(--text-black2);
  opacity: 0;
  transform: translateY(-0.6px);
}
.navigation-list .active {
  text-decoration: underline;
}
.navigation.is-open .navigation-list a {
  opacity: 1;
  transform: translateY(0);
}
.navigation .navigation-list li:nth-child(1) a {
  transition: all 275ms 175ms;
}
.navigation .navigation-list li:nth-child(2) a {
  transition: all 275ms 225ms;
}
.navigation .navigation-list li:nth-child(3) a {
  transition: all 275ms 275ms;
}
.navigation .navigation-list li:nth-child(4) a {
  transition: all 275ms 325ms;
}
.navigation .navigation-list li:nth-child(5) a {
  transition: all 275ms 375ms;
}
.nav-actions {
  height: 40vh;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.nav-actions-col1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}
.nav-actions-col1 a,
.nav-actions-col1 img {
  margin: 0 0.5rem;
  opacity: 0;
  transform: translateY(-0.8rem);
  transition: all 295ms 395ms;
}
.nav-actions-col1 img {
  transition: all 305ms 405ms;
}
.navigation.is-open .nav-actions-col1 a,
.navigation.is-open .nav-actions-col1 img {
  opacity: 1;
  transform: translateY(0);
}
.cta-btn {
  min-width: 14rem;
  height: 2.8rem;
  display: grid;
  place-items: center;
  font-size: 1rem;
  /* font-weight: bold; */
  color: var(--text-white);
  background: var(--gradient);
  border: 0;
  outline: 0;
  border-radius: var(--br);
  opacity: 0;
  transform: translateY(-0.8rem);
  transition: all 325ms 425ms;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
}
.navigation.is-open .nav-actions > .cta-btn {
  opacity: 1;
  transform: translateY(0);
}
/* hamburger */
.hamburger {
  padding-right: 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index: 10000;
}
.hamburger:hover {
  opacity: 0.85;
}
.hamburger.is-active:hover {
  opacity: 0.85;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: var(--bg-black);
}
.hamburger-box {
  width: 30px;
  height: 25px;
  display: inline-block;
  position: relative;
  margin-bottom: -5px;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 35px;
  height: 2.2px;
  background-color: var(--bg-black);
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
