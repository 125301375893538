.clients-project-section {
  position: relative;
  margin-bottom: 6rem !important;
  overflow: hidden;
}
.clients-project-section::before {
  content: "";
  background: url("../asset/Rectangle\ 4.svg");
  position: absolute;
  background-repeat: no-repeat;
  background-position: right center;
  transform: translateX(-20px);
  z-index: -1;
}
.swiper {
  width: 100%;
  height: auto;
}
.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px 15px;
}
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  background-image: url("../asset/clientsbg.png");
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  border-radius: 4px !important;
}
.creative-project-card {
  padding: 5rem 2.5rem 2.5rem;
  max-width: 400px;
  min-height: 450px;

  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  border-radius: 0.3rem;
  box-shadow: var(--bs);
}
.project-logo {
  width: 13rem;
  max-width: 100%;
  margin-bottom: 2rem;
}
.cpc-6 .project-logo {
  width: 10rem;
}
.creative-project-card p {
  font-size: var(--text-size);
  color: var(--text-black3);
  margin-bottom: 2rem;
  line-height: var(--lh);
}
.creative-project-card a {
  width: 12rem;
  height: 2.8rem;
  margin: 0 auto;
  display: grid;
  place-items: center;
  font-size: var(--btn-text);
  font-weight: 600;
  color: var(--text-white);
  background: var(--primary-clr1);
  border-radius: var(--br);
}
.cpc-1 {
  padding-top: 2.5rem;
}
.cpc-1 img,
.cpc-3 img {
  transform: scale(1.2);
}
@media screen and (min-width: 700px) {
  .swiper-slide {
    padding: 60px 15px;
  }
}
@media screen and (min-width: 1000px) {
  .clients-project-section {
    width: 84%;
    margin: 0 auto;
    max-width: 1800px;
    border-radius: 8px;
    border: 1px solid white;
  }
  .clients-project-section::before {
    right: 0;
    top: 0;
    left: 0;
    right: -6rem;
    bottom: 0;
    justify-content: flex-end;
    align-items: center;
  }
  .swiper-slide {
    padding: 60px 60px 50px;
  }
  .creative-project-card {
    padding: 4rem 3rem;
    margin: 0;
    margin-right: auto;
  }
  .swiper-button-next:after {
    padding-right: 50px;
  }
  .swiper-button-prev:after {
    padding-left: 50px;
  }
}
