.about-hero {
  position: relative;
  height: 33rem;
  /* height: 75vh; */
  margin-bottom: clamp(10rem, 5vw, 12.75rem);
  background: url("../asset/bg-about.png");
  background-size: cover;
  background-position: center;
}
.about-hero::before {
  content: url("../asset/Rectangle\ 4.svg");
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  bottom: -6rem;
  z-index: -1;
}
/* ===================
        IDENTITY
    ==================  */
.brand-identity {
  max-width: 80ch;
  margin: 0 auto clamp(5rem, 5vw, 10.6rem);
  text-align: center;
}
.brand-identity h1 {
  font-size: clamp(2.2rem, 5vw, 3rem);
  margin-bottom: clamp(1.5rem, 5vw, 1rem);
}
.brand-identity p {
  line-height: var(--lh);
}
.brand-identity p:nth-child(2) {
  margin-bottom: 1.6rem;
}
/* ===================
        GALLERY
    ==================  */
.gallery-content {
  margin-bottom: clamp(5rem, 5vw, 10.6rem);
}
.galleries {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background-color: var(--bg-yellow);
  gap: 0.8rem;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.gallery-img1 {
  grid-column: 1 / 7;
  grid-row: 1/7;
}
.gallery-img2 {
  grid-column: 7 / 13;
  grid-row: 1/13;
}
.gallery-img3 {
  grid-column: 1 / 7;
  grid-row: 7/13;
}
.gallery-img1 img,
.gallery-img2 img,
.gallery-img3 img {
  width: 100%;
  height: 100%;
}
.gallery-text-content-wrapper {
  background-color: var(--bg-yellow);
  border-radius: 0 0 4px 4px;
}
.gallery-text-content {
  text-align: center;
  margin: 0 auto;
  padding: clamp(3rem, 5vw, 5.4rem) 0;
}
.gallery-text-content h2 {
  font-size: clamp(2.3rem, 5vw, 3rem);
  margin-bottom: clamp(1.5rem, 5vw, 2rem);
}
.gallery-text-content p {
  max-width: 50ch;
  margin: 0 auto clamp(1.5rem, 5vw, 2rem);
  line-height: var(--lh);
}
.gallery-text-content a {
  width: 11.75rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--btn-text);

  margin: 0 auto;
  color: var(--text-white);
  background-color: var(--bg-black);
  border-radius: var(--br);
}
/* ===================
        TEAM
    ==================  */
.team-section {
  padding-bottom: clamp(4rem, 5vw, 8rem);
  margin-bottom: clamp(4rem, 5vw, 8rem);
  border-bottom: 1px solid #eaeaea;
}
.team-heading {
  margin-bottom: clamp(2.2rem, 5vw, 2rem);
}
.team-heading h3 {
  font-size: clamp(2.3rem, 5vw, 3rem);
  padding-bottom: clamp(1.2rem, 5vw, 1.4rem);
}
.team-heading p {
  max-width: 45ch;
  line-height: var(--lh);
}
.teams {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}
.team-card img {
  margin-bottom: clamp(0.6rem, 5vw, 0.8rem);
  border-radius: 5px;
}
.team-card p:nth-child(2) {
  margin-bottom: 2px;
  font-weight: 600;
}
.team-card p:nth-child(3) {
  font-size: 14px;
  color: var(--text-gray);
}
@media screen and (min-width: 1000px) {
  .gallery-content-container {
    width: 88%;
    max-width: 1500px;
    margin: 0 auto;
  }
}
