*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primary-clr1: #fd4101;
  --primary-clr2: #ffad01;
  --gradient: linear-gradient(84.31deg, #fd4101 9.27%, #ffad01 96.84%);
  --text-size: 1.1rem;
  --btn-text: 1.1rem;
  --text-black: #000000;
  --text-black2: #222222;
  --text-black3: #333333;
  --text-black4: #444444;
  --text-gray: #777777;
  --text-gray2: #8f8f8f;
  --text-white: #ffffff;
  --bg-white: #ffffff;
  --bg-black: #000000;
  --bg-black2: #1b1b1b;
  --bg-gray: #8f8f8f;
  --bg-yellow: #ffad01;

  --bs: 0px 70px 80px rgba(0, 0, 0, 0.1);
  --br: 28px;
  --lh: 1.5;
  --spacing1: 1rem;
  --spacing2: 1.3rem;
  --transition: all 0.3s ease-in-out;
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Gilroy-SemiBold"),
    url(/src/font/gilroy/Gilroy-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(/src/font/gilroy/Gilroy-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url(/src/font/gilroy/Gilroy-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-RegularItalic";
  src: local("Gilroy-RegularItalic"),
    url(/src/font/gilroy/Gilroy-RegularItalic.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy-UltraLightItalic";
  src: local("Gilroy-UltraLightItalic"),
    url(/src/font/gilroy/Gilroy-UltraLightItalic.ttf) format("truetype");
}
html,
body {
  min-height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
}
body {
  position: relative;
  font-family: "Gilroy-Medium", "Roboto";
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Gilroy-SemiBold", "Roboto"; */
  font-family: "Gilroy-Regular", "Roboto";

  font-weight: 700;
  line-height: 1.2;
}
p {
  font-size: var(--text-size);
  font-weight: 500;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  transition: var(--transition);
}
a:hover {
  opacity: 0.85 !important;
}
ul {
  padding: 0;
}
button {
  font: inherit;
  cursor: pointer;
}
img {
  max-width: 100%;
}
.container {
  width: 88%;
  margin: 0 auto;
  max-width: 1500px;
}
* {
  -webkit-tap-highlight-color: transparent;
}

.services-page .services-content {
  width: 100%;
}
@media screen and (min-width: 1400px) {
  :root {
    --text-size: 1.15rem;
  }
}
.privacy-screen {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary-clr2);
  z-index: 10000000000;
}
